import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout";
import utils from "@/utils/utils";

Vue.use(VueRouter);


// 在重写push|replace方法时，先把VueRouter原型对象的push|replace保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
// 参数1：告诉原来的push | replace方法，你往那里跳转（传递那些参数）
// 参数2：成功回调
// 参数3：失败回调
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}


export const routes = [
  {
    path: "/",
    name: "index",
    redirect: '/index',
    meta: {
      icon: 'home'
    },
    component: Layout,
    children: [
      {
        name: '首页',
        path: '/home',
        meta: {
          icon: 's-home'
        },
        component: () => import('@/views/home/index.vue'),
      },{
        name: '我的课程',
        path: '/course',
        meta: {
          icon: 'video-camera-solid'
        },
        component: () => import('@/views/course/index.vue'),
      },{
        name: '我的考试',
        path: '/examination',
        meta: {
          icon: 's-platform'
        },
        component: () => import('@/views/examination/index.vue'),
      },
      {
        name: '帮助中心',
        path: '/about',
        meta: {
          icon: 's-custom'
        },
        component: () => import('@/views/about/about.vue'),
      },
      {
        name: '个人中心',
        path: '/user',
        meta: {
          icon: 's-custom'
        },
        component: () => import('@/views/user/index.vue'),
      }
    ]
  },
  {
    path: '/index',
    name: "index",
    meta: {
      icon: 'home'
    },
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/login',
    name: "login",
    meta: {
      icon: 'home'
    },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/formal',
    name: "formal",
    meta: {
      icon: 'home'
    },
    component: () => import('@/views/formal/index.vue')
  },
  {
    path: '/regular/exam',
    name: "RegularExam",
    component: () => import('@/views/formal/pages/RegularExam.vue')
  },
  {
    path: '/random/exam',
    name: "RandomExam",
    component: () => import('@/views/formal/pages/RandomExam.vue')
  },

  {
    path: '/mock/exam',
    name: "MockExam",
    component: () => import('@/views/mock/MockExam.vue')
  },{
    path: '/course/video',
    name: "CourseVideo",
    component: () => import('@/views/video/index.vue')
  },{
    path: "/exam-result",
    name: "ExamResult",
    component: () => import("@/components/result.vue")
  },{
    path: "/formal/daily/exam",
    name: "DailyExam",
    component: () => import("@/views/formal/pages/DailyExam.vue")
  },
  {
    path: "/filtration",
    name: "Filtration",
    component: () => import("@/views/filtration/index.vue")
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from,next) => {
  if(to.path === '/index') {
    return next()
  }

  if(to.path === '/filtration') {
    return next()
  }

  if(to.path !== '/login' && !utils.getStorage('TOKEN')) {
    return next('/login')
  }

  next()

})

export default router;
