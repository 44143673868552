import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/scss/gloab.scss"
import "./styles/scss/flex.scss"





import utils from "@/utils/utils";

Vue.config.productionTip = false;

Vue.prototype.$utils = utils
Vue.prototype.$httpUrl = process.env.VUE_APP_API_BASE_URL

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
